import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { concatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  IBroadcastOrg,
  IRegisterOrgReq,
  IOrganisationResponse,
  IUpdateDbReq,
  IEmailRegisterdResponse,
  IAppSettingsResponse,
  IAppSettings,
  ITimezoneListResponse,
  ITimezoneHistoryResponse,
  IGetUserCountry,
  IEmpPageAcessResponse,
  IAppSettingsUpdateMenuAccess,
  IAppCurrecnySettings,
  IAppUserCurrency,
  IGetUserState
} from '../models/organisation-user';
import { AuthenticationService } from './authentication.service';
import { IAPIResponse } from './dashboard.service';
interface CustomerResponse {
  stripeCustId: string;
}
@Injectable({
  providedIn: 'root'
})
export class OrganisationUserService {

  private url = `${environment.apiEndpoint}/api/v1/organisation-management/organisations`;

  constructor(
    private http: HttpClient
  ) { }

  registerOrganisation(newOrganisation: IRegisterOrgReq) {
    const reqUrl = this.url + '/register';
    return this.http.post(reqUrl, newOrganisation);
  }


  sentBroadcast(newBroadcast: IBroadcastOrg, organisationCode: string) {
    const requestUrl = `${environment.apiEndpoint}/api/v1/${organisationCode}/sys-app/notifications`;
    return this.http.post(requestUrl, newBroadcast);
  }

  getOrganisationByEmail(email: string, idToken: string) {
    return this.http.get<IOrganisationResponse>(`${this.url}/${email}`, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  }

  updateDatabaseConfiguration(request: IUpdateDbReq, idToken: string) {
    return this.http.put(`${this.url}/${request.organisationId}/db-config`, {
      databaseName: request.dbName,
      hostUrl: request.dbHostUrl,
      port: request.dbHostPort,
      userName: request.dbUsername,
      password: request.dbPassword
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  }

  testDatabaseConnection(request: IUpdateDbReq, idToken: string) {
    const reqUrl = `${environment.apiEndpoint}/api/v1/db-management/check-connection`;
    return this.http.post(reqUrl, {
      databaseName: request.dbName,
      hostUrl: request.dbHostUrl,
      userName: request.dbUsername,
      password: request.dbPassword,
      port: request.dbHostPort
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  }

  createOrgDB(request: IUpdateDbReq, idToken: string) {
    const reqUrl = `${environment.apiEndpoint}/api/v1/db-management/create-db`;
    return this.http.post(reqUrl, {
      databaseName: request.dbName,
      hostUrl: request.dbHostUrl,
      userName: request.dbUsername,
      password: request.dbPassword,
      port: request.dbHostPort
    }, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  }

  updateAndCreateDB(request: IUpdateDbReq, idToken: string) {
    return this.testDatabaseConnection(request, idToken)
      .pipe(concatMap(() => {
        return this.updateDatabaseConfiguration(request, idToken);
      }),
        concatMap(() => {
          return this.createOrgDB(request, idToken);
        }));
  }

  checkAvailability(email: string, orgCode: string): Observable<IEmailRegisterdResponse> {
    return this.http.post<IEmailRegisterdResponse>(`${this.url}/check-availability`, {
      email,
      organisationCode: orgCode
    });
  }

  getAppSettings(orgCode: string) {
    return this.http.get<IAppSettingsResponse>(`${this.url}/${orgCode}/app-settings`);
  }
  getUserCurrency(orgCode: string) {
    return this.http.get<IAppUserCurrency>(`${this.url}/${orgCode}/get-user-currency`);
  }
  getCurrencyList(orgCode: string) {
    return this.http.get<any>(`${this.url}/${orgCode}/get-currency`);
  }
  getEmployeePageAccess(orgCode: string) {
    return this.http.get<IEmpPageAcessResponse>(`${this.url}/${orgCode}/page-access-config`);
  }
  getEmployeeDashboardAccess(orgCode: string) {
    return this.http.get<IEmpPageAcessResponse>(`${this.url}/${orgCode}/employee-dashboard-access`);
  }

  getManagerAdminAccess(orgCode:string,employeeId :number) {
    const reqUrl = `${environment.apiEndpoint}/api/v1/managerial-operations/manager/admin-access`
    const query = `?organisationCode=${orgCode}&employeeId=${employeeId}`
    return this.http.get<IEmpPageAcessResponse>(reqUrl+query);
  }
  updateAppSettings(orgCode: string, updateReq: IAppSettings) { 
    return this.http.put(`${this.url}/${orgCode}/app-settings`, {
      screenshotFrequency: updateReq.screenshotFrequency,
      isDifferenceComparision: updateReq.isDifferenceComparision,
      screenshotDifference: updateReq.screenshotDifference,
      isIdlePeriodTracking: updateReq.isIdlePeriodTracking,
      idleTimeDuration: updateReq.idleTimeDuration,
      imageBlurPercent: updateReq.imageBlurPercent,
      takeScreenshots: updateReq.takeScreenshots,
      idleAlertTimeoutMinutes: updateReq.idleAlertTimeoutMinutes,
      appTrackingEnabled:updateReq.appTrackingEnabled,
      appTrackingApiInterval:updateReq.appTrackingApiInterval,
      idleMessageAlertInterval:updateReq.idleMessageAlertInterval,
      loginAccess:updateReq.loginAccess,
      // reportsPageAccess :updateReq.reportsPageAccess,
      // timelogSummaryPageAccess :updateReq.timelogSummaryPageAccess,
      // empReportPageAccess :updateReq.empReportPageAccess,
      // activityPatternPageAccess :updateReq.activityPatternPageAccess,
      // // advanceReportPageAccess :updateReq.advanceReportPageAccess,
      // topActivityPageAccess :updateReq.topActivityPageAccess,
      adminAutoStart: updateReq.adminAutoStart,
      autoBreakClose: updateReq.autoBreakClose,
      hourlyRateFeature: updateReq.hourlyRateFeature
    });
  }
  updateCurrencyAppSettings(orgCode: string, updateReq: IAppCurrecnySettings) {
    return this.http.put(`${this.url}/${orgCode}/update-currency`, {
      currency: updateReq.currency
    });
  }
  updateAppSettingsMenuAccess(orgCode: string, updateReq: IAppSettingsUpdateMenuAccess) {
    return this.http.post(`${this.url}/${orgCode}/update-page-access`, {
      menuName: updateReq.menuName,
      role: updateReq.role,
      access: updateReq.access,
    });
  }
  updateState(orgCode: string, payload: { state: string }) {
    return this.http.post(`${this.url}/${orgCode}/update-state`, payload);
  }
  updateLastLogin(orgCode: string, loginTime: Date) {
    return this.http.put(`${this.url}/${orgCode}/last-login`, {
      loginTimestamp: loginTime.toISOString()
    });
  }

  public getAllTimezone(orgCode: string) {
    return this.http.get<ITimezoneListResponse>(`${this.url}/${orgCode}/timezone`);
  }

  updateTimezone(orgCode: string, updateReq: { timezone: string,country:string}) {
    return this.http.put(`${this.url}/${orgCode}/update-custom-timezone`, {
      timezone: updateReq.timezone,
      country: updateReq.country
    });
  }

  upgradePremiumPlan(authService: AuthenticationService, billingAddress: any) {
    return authService.getCurretUserOrgCode()
      .pipe(
        concatMap((orgCode) => {
          return this.http.post<IAPIResponse<null>>(`${this.url}/${orgCode}/upgrade-premium`, { address: billingAddress })
        }));
  }
  public attachCard(paymentMethodId: string, customerId: string) {
    const reqUrl = `${this.url}/attach-card`;
    return this.http.post(reqUrl, { paymentMethodId, customerId });
  }
  getCustomerId(authService: AuthenticationService,) {
    return authService.getCurretUserOrgCode()
      .pipe(
        concatMap((orgCode) => {
          const reqUrl = `${this.url}/${orgCode}/get-custId`;
          return this.http.get<IAPIResponse<CustomerResponse>>(reqUrl);
        }));
  }
  public getTimezoneHistory(orgCode: string) {
    return this.http.get<ITimezoneHistoryResponse>(`${this.url}/${orgCode}/timezone-history`);
  }

  getUserCountryCode(orgCode: string){
    return this.http.get<IGetUserCountry>(`${this.url}/${orgCode}/get-country`);
  }
  getUserState(orgCode: string){
    return this.http.get<IGetUserState>(`${this.url}/${orgCode}/get-state`);
  }
  public retrivePremiumUserCheckoutPage(organisationCode: string): Observable<any> {
    const reqUrl = `${this.url}/${organisationCode}/checkout-dashboard`; // Ensure the correct backend endpoint
    return this.http.post<any>(reqUrl, {});
  }

}
